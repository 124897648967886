import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { forwardRef } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import styles from './SelectInput.module.scss';
import { useStyles } from './styles';
import { useAppSelector } from '../../App/hooks';
import { selectDirection } from '../../App/features/direction/directionSlice';
import { getRtlClass } from '../../Account/AddEducationForm';

interface SelectInputProps {
  width?: number;
  placeholder?: string;
  propValue?: number | string;
  inputRef?: React.Ref<any> | undefined;
  ref?: React.RefObject<HTMLDivElement>;
  options: { value: string | number; name: string }[];
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: '#aaa',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  (
    { width = 100, propValue, handleChange, options, placeholder = 'Experience', inputRef, ...props },
    ref,
  ): JSX.Element => {
    const classes = useStyles();
    const direction = useAppSelector(selectDirection);

    return (
      <FormControl className={getRtlClass(direction, classes.input, classes.inputRtl)} style={{ width: `${width}%` }}>
        <Select
          {...props}
          labelId="customized-select-label"
          id="customized-select"
          onChange={handleChange}
          IconComponent={props => <ExpandMoreIcon {...props} />}
          fullWidth
          inputRef={ref}
          value={propValue}
          renderValue={propValue !== 0 ? undefined : () => <Placeholder>{placeholder}</Placeholder>}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.menuPaper },
          }}>
          {options.map(option => (
            <MenuItem key={`${option.value}${option.name}`} className={styles.listItem} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
);

export default SelectInput;
