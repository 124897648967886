import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InstitutionRatings.module.scss';

interface RatingItem {
  name: string;
  value: number;
}

const InstitutionRatingsData: RatingItem[] = [
  {
    name: 'Cost and Fees (per year) ',
    value: 5,
  },
  {
    name: 'Education Level',
    value: 1,
  },
  {
    name: 'Internship and Career Opportunities',
    value: 3,
  },
  {
    name: 'Campus Life',
    value: 3,
  },
  {
    name: 'Campus Facilities',
    value: 2,
  },
];

const Grade: string[] = ['Awful', 'Bad', 'Ok', 'Good', 'Excellent'];

// FIXME: useTranslate,position

const InstitutionRatings = () => {
  const { t } = useTranslation('publicJobSidebar');

  return (
    <div className={styles.ratings}>
      <div className={styles.infoHeader}>
        <span className={styles.ratingsHeaderTitle}>
          <span>{t('InstitutionRatings.InstitutionRatings')}</span>
        </span>
      </div>
      <div className={styles.grades}>
        {Grade.map((grade, index) => (
          <span key={index} className={styles.grade}>
            {grade}
          </span>
        ))}
      </div>
      <div className={styles.ratingItems}>
        {InstitutionRatingsData.map((item, index) => (
          <div key={index} className={styles.ratingItem}>
            <span>{t(`ratings.${item.name}`)}</span>
            <div className={styles.ratingDivIcon}>
              {[...Array(item.value)].map((_, i) => (
                <span key={i} className={styles.ratingIcon}></span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstitutionRatings;
