import React from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import styles from './RouteWithSubRoutes.module.scss';
import { useAppSelector } from '../../hooks';
import { selectDirection } from '../../features/direction/directionSlice';

const RouteWitheSubRoutes = route => {
  const { isPrivate, path, exact, store, routes, withStore, showSidebar } = route;
  const componentProps = { routes: routes, store: withStore ? store : {} };
  const routeProps = { path: path, exact: exact };
  const direction = useAppSelector(selectDirection);

  return isPrivate ? (
    <div
      className={clsx(styles.content, showSidebar && styles.contentWithSidebar)}
      style={direction === 'rtl' ? { marginLeft: 'unset', marginRight: '1rem' } : undefined}>
      <PrivateRoute {...routeProps}>
        <route.component {...componentProps} />
      </PrivateRoute>
    </div>
  ) : (
    <div
      className={clsx(styles.publicContent, showSidebar && styles.publicContentWithSidebar)}
      style={direction === 'rtl' ? { marginLeft: 'unset', marginRight: '1rem' } : undefined}>
      <Route {...routeProps}>
        <route.component {...componentProps} />
      </Route>
    </div>
  );
};

export { RouteWitheSubRoutes as default };
