export const mainNavbarRoutes = [
  '/',
  '/about',
  '/company/:id',
  '/search',
  '/search-results',
  '/jobs/:id',
  '/account/create',
  '/account/check',
  '/account/recovery',
  '/account/profile',
  '/account/upload',
  '/account/education',
  '/account/experience',
  '/account/skills',
  '/account/languages',
  '/account/network',
  '/account/add-contacts',
  '/account/interests',
  '/account/job-search',
  '/account/workplace',
  // '/applications'
];
export const navbarRoutes = [
  '/',
  '/about',
  '/search',
  '/search-results',
  '/jobs/:id',
  '/company/:id',
  '/company/:id/jobs',
  '/account/profile/edit',
  '/account/upload/edit',
  '/account/education/edit',
  '/account/experience/edit',
  '/account/skills/edit',
  '/account/languages/edit',
  '/account/network/edit',
  '/account/add-contacts/edit',
  '/account/interests/edit',
  '/account/job-search/edit',
  '/account/workplace/edit',
  '/career/job-search/edit',
  '/career/workplace/edit',
  '/account/security',
  '/account/language',
  '/account/status',
  // '/applications'
];
export const excludeFooterRoutes = [
  '/account/check',
  '/account/recovery',
  '/account/profile',
  '/account/upload',
  '/account/education',
  '/account/experience',
  '/account/skills',
  '/account/languages',
  '/jobs/:id',
  '/company/:id',
  '/company/:id/jobs',
  '/account/network',
  '/account/add-contacts',
  '/account/interests',
  '/account/job-search',
  '/account/workplace',
  '/account/profile/edit',
  '/account/upload/edit',
  '/account/education/edit',
  '/account/experience/edit',
  '/account/skills/edit',
  '/account/languages/edit',
  '/account/network/edit',
  '/account/add-contacts/edit',
  '/account/interests/edit',
  '/account/job-search/edit',
  '/account/workplace/edit',
  '/career/job-search/edit',
  '/career/workplace/edit',
  '/account/security',
  '/account/language',
  '/account/status',
];
export const hideNavigationRoutes = [
  '/account/profile',
  '/account/upload',
  '/account/education',
  '/account/experience',
  '/account/skills',
  '/account/languages',
  '/account/network',
  '/account/add-contacts',
  '/account/interests',
  '/account/job-search',
  '/account/workplace',
]

export const hideSidebarRoutes = [
  '/account/profile',
  '/account/upload',
  '/account/education',
  '/account/experience',
  '/account/skills',
  '/account/languages',
  '/account/network',
  '/account/add-contacts',
  '/account/interests',
  '/account/job-search',
  '/account/workplace',
  '/terms',
  '/privacy',
  '/about',
  '/jobs/:id',
  '/company/:id',
  '/company/:id/jobs',
]

export const backgroundImageRoutes = []

export const notAuthRouts: string[] = [
  '/account/check', '/account/recovery', 
];
